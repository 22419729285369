var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"memod-form"},[_c('upload-image-modal',{on:{"uploaded-image":_vm.uploadedImage}}),_c('suggest-topic-modal',{on:{"suggested-topic":_vm.handleSuggestedTopic}}),_c('suggest-topic-thanks'),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitMemo)}}},[_c('div',{staticClass:"steps-buttons container"},[_c('div',{staticClass:"back-button",on:{"click":_vm.goPrevStep}},[_c('img',{attrs:{"src":require("@assets/img/icons/left-chevron.svg"),"width":"10","alt":"Back"}})]),_c('button',{staticClass:"btn btn-outline-primary ml-auto",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('show-memo-preview')}}},[_vm._v(" Preview ")]),_c('button',{staticClass:"btn btn-primary next-button ml-3",attrs:{"disabled":!valid}},[_vm._v(" Publish ")])]),_c('memo-images-carousel',{ref:"memo_images_carousel",attrs:{"show-carousel-actions":"","memo-images":_vm.memoImages},on:{"mark-cover-image":function (filesystemId) { return _vm.$emit('mark-cover-image', filesystemId); },"delete-image":function (filesystemId) { return _vm.$emit('delete-image', filesystemId); }}}),_c('div',{staticClass:"form-container"},[_c('ValidationProvider',{ref:"topicsValidator",attrs:{"name":"topics","rules":"required|max-length:4,topics"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group topics-multiselect-container",class:{ 'required' : errors.length }},[_c('label',[_vm._v("Topics")]),_c('topics-multiselect',{attrs:{"value":_vm.topics,"show-suggest-new-topic":(_vm.topics.length < 4) && (_vm.suggestedTopics.length <= 3),"placeholder":"What’s your Memo about? Type in 1-4 topics"},on:{"input":_vm.handleSelectedTopics,"remove-topic":_vm.removeTopic}}),(errors.length)?_c('span',{staticClass:"instructions"},[_vm._v(_vm._s(errors[0]))]):(_vm.suggestedTopics.length)?_c('span',{staticClass:"instructions"},[_vm._v("You are suggesting one or more new topics to our community.")]):_c('span',{staticClass:"instructions"},[_vm._v("Select a topic from the list or write a new one that you would like to suggest.")])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Inspired by","rules":_vm.is_original ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'required' : errors.length, 'disabled': _vm.is_original }},[_c('label',{attrs:{"for":"source-title"}},[_vm._v("Inspired by "),(errors.length)?_c('span',[_vm._v("("+_vm._s(errors[0])+")")]):_vm._e()]),_c('input',{staticClass:"form-control",attrs:{"id":"source-title","type":"text","placeholder":"Name of the book, podcast, e.g. ‘The Art of War by Sun Tzu’","maxlength":"100","disabled":Boolean(_vm.is_original)},domProps:{"value":_vm.source_title},on:{"input":function (event) { return _vm.$emit('update:source_title', event.target.value); }}})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"mode":"lazy","name":"Source URL","rules":_vm.is_original ? '' : 'link'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'required' : errors.length, 'disabled': _vm.is_original }},[_c('label',{attrs:{"for":"source-link"}},[_vm._v("Source URL "),(errors.length)?_c('span',[_vm._v("("+_vm._s(errors[0])+")")]):_vm._e()]),_c('input',{staticClass:"form-control",attrs:{"id":"source-link","type":"text","placeholder":"Provide a link to the Source e.g. the Art of War page on Amazon.com","disabled":Boolean(_vm.is_original)},domProps:{"value":_vm.source_link},on:{"input":function (event) { return _vm.$emit('update:source_link', event.target.value); }}})])]}}],null,true)}),_c('div',{staticClass:"form-group is-original-memo"},[_c('label',[_vm._v("Original Memo")]),_c('switches',{attrs:{"value":_vm.is_original,"type-bold":"false"},on:{"input":function (newValue) { return _vm.$emit('update:is_original', Number(newValue)); }}})],1),_c('p',{staticClass:"original-memo-text"},[_vm._v(" Original Memos are based on your own ideas or expertise and don't require a Source ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }