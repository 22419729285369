<template>
    <div class="memod-form">
        <upload-image-modal @uploaded-image="uploadedImage" />
        <suggest-topic-modal @suggested-topic="handleSuggestedTopic" />
        <suggest-topic-thanks />
        <ValidationObserver v-slot="{ valid, handleSubmit }" slim>
            <form novalidate @submit.prevent="handleSubmit(submitMemo)">
                <div class="steps-buttons container">
                    <div class="back-button" @click="goPrevStep">
                        <img src="@assets/img/icons/left-chevron.svg" width="10" alt="Back">
                    </div>
                    <button
                        type="button"
                        class="btn btn-outline-primary ml-auto"
                        @click="$emit('show-memo-preview')"
                    >
                        Preview
                    </button>
                    <button :disabled="!valid" class="btn btn-primary next-button ml-3">
                        Publish
                    </button>
                </div>
                <memo-images-carousel
                    ref="memo_images_carousel"
                    show-carousel-actions
                    :memo-images="memoImages"
                    @mark-cover-image="(filesystemId) => $emit('mark-cover-image', filesystemId)"
                    @delete-image="(filesystemId) => $emit('delete-image', filesystemId)"
                />
                <div class="form-container">
                    <ValidationProvider
                        v-slot="{ errors }"
                        ref="topicsValidator"
                        name="topics"
                        rules="required|max-length:4,topics"
                    >
                        <div :class="{ 'required' : errors.length }" class="form-group topics-multiselect-container">
                            <label>Topics</label>
                            <topics-multiselect
                                :value="topics"
                                :show-suggest-new-topic="(topics.length < 4) && (suggestedTopics.length <= 3)"
                                placeholder="What’s your Memo about? Type in 1-4 topics"
                                @input="handleSelectedTopics"
                                @remove-topic="removeTopic"
                            />
                            <span v-if="errors.length" class="instructions">{{ errors[0] }}</span>
                            <span v-else-if="suggestedTopics.length" class="instructions">You are suggesting one or more new topics to our community.</span>
                            <span v-else class="instructions">Select a topic from the list or write a new one that you would like to suggest.</span>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Inspired by"
                        :rules="is_original ? '' : 'required'"
                    >
                        <div :class="{ 'required' : errors.length, 'disabled': is_original }" class="form-group">
                            <label for="source-title">Inspired by <span v-if="errors.length">({{ errors[0] }})</span></label>
                            <input
                                id="source-title"
                                :value="source_title"
                                type="text"
                                class="form-control"
                                placeholder="Name of the book, podcast, e.g. ‘The Art of War by Sun Tzu’"
                                maxlength="100"
                                :disabled="Boolean(is_original)"
                                @input="(event) => $emit('update:source_title', event.target.value)"
                            >
                        </div>
                    </ValidationProvider>
                    <ValidationProvider
                        v-slot="{ errors }"
                        mode="lazy"
                        name="Source URL"
                        :rules="is_original ? '' : 'link'"
                    >
                        <div :class="{ 'required' : errors.length, 'disabled': is_original }" class="form-group">
                            <label for="source-link">Source URL <span v-if="errors.length">({{ errors[0] }})</span></label>
                            <input
                                id="source-link"
                                :value="source_link"
                                type="text"
                                class="form-control"
                                placeholder="Provide a link to the Source e.g. the Art of War page on Amazon.com"
                                :disabled="Boolean(is_original)"
                                @input="(event) => $emit('update:source_link', event.target.value)"
                            >
                        </div>
                    </ValidationProvider>
                    <div class="form-group is-original-memo">
                        <label>Original Memo</label>
                        <switches :value="is_original" type-bold="false" @input="(newValue) => $emit('update:is_original', Number(newValue))" />
                    </div>
                    <p class="original-memo-text">
                        Original Memos are based on your own ideas or expertise and don't require a Source
                    </p>
                </div>
            </form>
        </validationObserver>
    </div>
</template>

<script>
/* eslint-disable vue/prop-name-casing */
import Switches from "vue-switches";

export default {
    name: "MemodForm",
    components: {
        // CoverImage: () => import(/* webpackChunkName: "cover-image" */ "./cover-image"),
        Switches,
        uploadImageModal: () => import(/* webpackChunkName: "upload-image-modal" */ "@c/organisms/modals/upload-image-modal"),
        suggestTopicModal: () => import(/* webpackChunkName: "suggest-topic-modal" */ "@c/organisms/modals/suggest-topic/suggest-topic"),
        suggestTopicThanks: () => import(/* webpackChunkName: "suggest-topic-thanks" */ "@c/organisms/modals/suggest-topic/thank-you"),
        memoImagesCarousel: () => import(/* webpackChunkName: "memo-images-carousel" */ "@c/organisms/memo-images-carousel"),
        topicsMultiselect: () => import(/* webpackChunkName: "topics-multiselect" */ "@c/molecules/topics-multiselect")
    },
    props: {
        activeStep: {
            type: Number,
            default: 0
        },
        isDraftMemo: {
            type: Boolean,
            required: true
        },
        topics: {
            type: Array,
            required: true
        },
        source_title: {
            type: String,
            required: true
        },
        source_link: {
            type: String,
            required: true
        },
        is_original: {
            type: Number,
            required: true
        },
        memoImages: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    computed: {
        suggestedTopics() {
            // eslint-disable-next-line no-prototype-builtins
            return this.topics.filter(topic => topic.hasOwnProperty("is_approved"));
        }
    },
    methods: {
        uploadedImage(file) {
            this.$emit("memo-image-uploaded", file);
        },
        submitMemo() {
            this.$emit("save-memo");
        },
        saveDraft() {
            this.$emit("save-draft");
        },
        goPrevStep() {
            this.$emit("go-prev-step");
        },
        handleSelectedTopics(topics) {
            if (topics.length <= 4) {
                this.$emit("update:topics", topics)
            }
        },
        handleSuggestedTopic(suggestedTopic) {
            const topics = this.topics;
            topics.push(suggestedTopic);
            this.handleSelectedTopics(topics)
        },
        removeTopic(topicToDelete) {
            const newTopics = this.topics;
            const topicToDeleteIndex = newTopics.findIndex(topic => topic.id == topicToDelete.id);
            newTopics.splice(topicToDeleteIndex, 1);
            this.$refs.topicsValidator.validate();
        }
    }
}
</script>

<style lang="scss">
.memod-form {
    margin-bottom: 150px;

    .steps-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 4vh;

        @media(max-width: $md) {
            padding: 20px;

            .btn {
                padding: 12px 24px;
            }
        }
    }

    .steps {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px);
        padding-top: 5vh;

        @media(max-width: $md) {
            padding-top: 0;
        }

        .step-container-border {
            border: 1px solid #2a292b;
            border-radius: 10px;
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;

            @media(max-width: $md) {
                border: 0;
            }
        }
    }

    .topics-multiselect-container {
        .instructions {
            margin-top: 5px;
            color: $primary-color;
            display: block;
        }

        &.required {
            .instructions {
                color: $error-color;
            }
        }
    }

    .back-button {
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .form-container {
        @media(max-width: $md) {
            padding-left: 15px;
            padding-right: 15px;
        }

        .is-original-memo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;

            label {
                font-size: 18px;
            }

            .vue-switcher {
                div {
                    &:after {
                        background-image: url("~@assets/img/icons/star.svg");
                    }
                }
            }
        }
    }

    .original-memo-text {
        font-size: 14px;
        color: #D7D9DB;
    }
}
</style>
